import React, {useRef, useEffect} from "react";
import {Runtime, Inspector} from "@observablehq/runtime";
import notebook from "@m-clare/se-abbrev";


function LicensesPerYear() {
  const structuralChartRef = useRef();
  const viewofSelectRef = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(notebook, name => {
      if (name === "structuralChart") return new Inspector(structuralChartRef.current);
      if (name === "viewof select") return new Inspector(viewofSelectRef.current);
      return ["chart","focus","chart2","chart3","delinquentStructureYearUpTo","activeStructureYearUpTo","BarAndLineChart"].includes(name);
    });
    return () => runtime.dispose();
  }, []);

  return (
    <>
      <div ref={viewofSelectRef} />
      <div ref={structuralChartRef} />
      <p>Credit: <a href="https://observablehq.com/@m-clare/se-licensure">Structural (SE) and Civil Engineering-Structural (PE) Licensure in California, Illinois, Texas, and Massachusetts by Maryanne Wachter</a></p>
    </>
  );
}
function LicenseAge() {
  const viewofSelectRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(notebook, name => {
      if (name === "viewof select") return new Inspector(viewofSelectRef.current);
      if (name === "chart") return new Inspector(chartRef.current);
      return ["structuralChart","focus","chart2","chart3","delinquentStructureYearUpTo","activeStructureYearUpTo","BarAndLineChart"].includes(name);
    });
    return () => runtime.dispose();
  }, []);

  return (
    <>
      <div ref={viewofSelectRef} />
      <div ref={chartRef} />
      <p>Credit: <a href="https://observablehq.com/@m-clare/se-licensure">Structural (SE) and Civil Engineering-Structural (PE) Licensure in California, Illinois, Texas, and Massachusetts by Maryanne Wachter</a></p>
    </>
  );
}

function LicenseOverTime() {
  const viewofSelectRef = useRef();
  const viewofLicenseYearRef = useRef();
  const chart2Ref = useRef();
  const chart3Ref = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(notebook, name => {
      if (name === "viewof select") return new Inspector(viewofSelectRef.current);
      if (name === "viewof licenseYear") return new Inspector(viewofLicenseYearRef.current);
      if (name === "chart2") return new Inspector(chart2Ref.current);
      if (name === "chart3") return new Inspector(chart3Ref.current);
      return ["structuralChart","chart","focus","delinquentStructureYearUpTo","activeStructureYearUpTo","BarAndLineChart"].includes(name);
    });
    return () => runtime.dispose();
  }, []);

  return (
    <>
      <div ref={viewofSelectRef} />
      <div ref={viewofLicenseYearRef} />
      <div ref={chart2Ref} />
      <div ref={chart3Ref} />
      <p>Credit: <a href="https://observablehq.com/@m-clare/se-licensure">Structural (SE) and Civil Engineering-Structural (PE) Licensure in California, Illinois, Texas, and Massachusetts by Maryanne Wachter</a></p>
    </>
  );
}

export {LicensesPerYear, LicenseAge, LicenseOverTime};
